@import "~normalize.css";

:root {
  --app-height: 100%;
}

body {
  font-family: Microsoft JhengHei, sans-serif;
}

input:focus,
textarea:focus,
select:focus,
button:focus {
  outline: 0;
}

button {
  padding: 0;
  cursor: pointer;
  background-color: transparent;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

.video-container {
  position: relative;

  padding-bottom: 56.25%;

  padding-top: 30px;

  height: 0;

  overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;

  top: 0;
  left: 0;

  width: 100%;

  height: 100%;
}

.lock-scroll {
  overflow: hidden;
  position: relative;
  height: 100%;
}
